import React, { forwardRef } from 'react';

import {
  get, identity, set, map,
} from 'lodash';

import Checkbox from 'lib-frontend-shared/src/components/Checkbox';
import Linear from 'lib-frontend-shared/src/components/Linear';
import MultiSelectAutocomplete from 'lib-frontend-shared/src/components/MultiSelectAutocomplete';
import Radio from 'lib-frontend-shared/src/components/Radio';
import Switch from 'lib-frontend-shared/src/components/Switch';
import Typography from 'lib-frontend-shared/src/components/Typography';

import { countries } from '../enums';

import ChipSelect from './ChipSelect';
import CityStatePicker from './CityStatePicker';
import KeyValTable from './KeyValTable';
import MaterialAutocomplete from './MaterialAutocomplete';
import MerchantSelect from './MerchantSelect';
import PasswordField from './PasswordField';
import QuantitySelect from './QuantitySelect';
import ReadOnlyField from './ReadOnlyField';
import Schedule from './Schedule';
import TextField from './smb/fields/TextField';
import UnifiedLabel, { defaultAnnotationProps } from './UnifiedLabel';
import What3WordsInput from './What3WordsInput';

const CenteredRenderer = ({ label }) => (
  <Linear justify="center" width="100pr">
    {label}
  </Linear>
);

const autocompleteRenderer = {
  center: CenteredRenderer,
};

const isMaterialAutocomplete = Array.prototype.includes.bind([
  'city-state',
  'country',
  'countries',
  'multiselect',
  'quantity',
  'select',
]);

const isTextField = Array.prototype.includes.bind([
  'email',
  'text',
  'tel',
  'number',
]);

const makeTextFieldProps = ({
  style = {},
  textFieldProps,
  type,
  align,
  inputProps,
  RenderComponent = isMaterialAutocomplete(type)
    ? autocompleteRenderer[align]
    : undefined,
  ...rest
}) => ({
  align,
  RenderComponent,
  style: {
    width: type === 'multiselect' ? '480px' : '250px',
    ...style,
  },
  textFieldProps,
  ...(isTextField(type) ? { // eslint-disable-line no-nested-ternary
    inputProps: {
      ...(inputProps || {}),
      style: {
        ...(align === 'center' ? { textAlign: 'center' } : {}),
        ...(inputProps?.style || {}),
      },
    },
  } : isMaterialAutocomplete(type) ? {
    isNewDesign: true,
    textFieldProps: {
      ...(textFieldProps || {}),
      inputProps: {
        ...(inputProps || {}),
        ...(textFieldProps?.inputProps || {}),
        style: {
          ...(align === 'center' ? { textAlign: 'center' } : {}),
          ...(textFieldProps?.inputProps?.style || {}),
        },
      },
    },
  } : {
    inputProps,
  }),
  ...rest,
});

const defaultOptions = (type) => {
  if (['country', 'countries'].includes(type)) return countries;
  return undefined;
};

const defaultSetter = ({
  field, defaultOnChange: onChange, options, sanitizer = identity, type,
}) => {
  const setField = (value) => onChange(set({}, field, value));
  if (type === 'checkbox') {
    return ({ target: { checked } }, ...rest) => setField(checked, ...rest);
  }
  if (type === 'toggle') {
    return ({ target: { checked } }, ...rest) => setField(options ? options[checked] : checked, ...rest);
  }
  if (['countries', 'multiselect'].includes(type)) {
    return (update, ...rest) => setField(map(update, 'value'), ...rest);
  }
  if ([
    'country',
    'chip-select',
    'city-state',
    'key-val',
    'merchants',
    'quantity',
    'select',
    'schedule',
    'what3words',
  ].includes(type)) return setField;
  return ({ target }, ...rest) => setField(sanitizer(target.value), ...rest);
};

const UnifiedInputBase = forwardRef(({
  config, defaultOnChange, defaultValue, field, sanitizer, type, useNewAutocomplete, ...props
}, ref) => {
  const {
    onChange = defaultSetter({
      defaultOnChange, field, sanitizer, type, ...props,
    }),
    transform = ({ value }) => value,
    value = get(config, field, defaultValue),
    ...rest
  } = props;
  const { options = defaultOptions(type) } = props;
  const commonProps = {
    onChange, ref, value, transform, ...rest,
  };
  const textFieldProps = makeTextFieldProps({ ...commonProps, type });
  if (type === 'checkbox') {
    return (
      <Checkbox
        color="secondary"
        checked={Boolean(value)}
        {...commonProps}
      />
    );
  }
  if (type === 'toggle') {
    return (
      <Switch
        color="secondary"
        checked={options ? value === options.true : Boolean(value)}
        {...commonProps}
        margin={false}
      />
    );
  }
  if (type === 'key-val') {
    return <KeyValTable {...commonProps} margin={false} variant="outlined" />;
  }
  if (type === 'merchants') {
    return <MerchantSelect {...commonProps} />;
  }
  if (type === 'chip-select') {
    return <ChipSelect clearable={false} {...commonProps} />;
  }
  if (type === 'radio') {
    return <Radio {...commonProps} options={options} />;
  }
  if (type === 'schedule') {
    return <Schedule {...commonProps} />;
  }
  if (type === 'what3words') {
    return <What3WordsInput {...commonProps} />;
  }
  if (['country', 'select'].includes(type)) {
    return useNewAutocomplete ? (
      <MultiSelectAutocomplete
        clearable={false}
        allowFreeText={false}
        multiple={false}
        {...textFieldProps}
        {...textFieldProps?.InputProps}
        allowedOptions={options}
      />
    ) : (
      <MaterialAutocomplete
        clearable={false}
        freeSolo={false}
        {...textFieldProps}
        options={options}
      />
    );
  }
  if (type === 'city-state') {
    return (
      <CityStatePicker
        clearable={false}
        freeSolo={false}
        {...textFieldProps}
      />
    );
  }
  if (['countries', 'multiselect'].includes(type)) {
    return useNewAutocomplete ? (
      <MultiSelectAutocomplete
        clearable={false}
        allowFreeText={false}
        {...textFieldProps}
        {...textFieldProps?.InputProps}
        allowedOptions={options}
      />
    ) : (
      <MaterialAutocomplete
        clearable={false}
        freeSolo={false}
        {...textFieldProps}
        options={options}
        multiple
      />
    );
  }
  if (type === 'quantity') return <QuantitySelect {...textFieldProps} />;
  if (type === 'password') {
    return <PasswordField hideLabel {...textFieldProps} />;
  }
  if (type === 'read-only') {
    return <ReadOnlyField {...textFieldProps} />;
  }
  if (isTextField(type)) {
    return <TextField {...textFieldProps} type={type} />;
  }
  return (
    <Typography variant="para.xs">
      {transform({ ...commonProps, config })}
    </Typography>
  );
});

const UnifiedInput = forwardRef((props, ref) => {
  const { field, fieldConfig = {} } = props;
  const {
    annotationLayoutProps,
    description,
    descriptionProps,
    label,
    helpMessage,
    tooltip,
    labelProps,
    width,
    ...params
  } = { id: field, ...fieldConfig[field], ...props };
  const defaultProps = defaultAnnotationProps[params.type] || {};
  return (
    <UnifiedLabel
      {...params}
      annotationLayoutProps={annotationLayoutProps}
      label={label}
      labelProps={{ ...defaultProps.labelProps, ...labelProps }}
      description={description}
      descriptionProps={{ ...defaultProps.descriptionProps, ...descriptionProps }}
      tooltip={tooltip}
      width={width}
      helpMessage={helpMessage}
    >
      <UnifiedInputBase {...params} ref={ref} />
    </UnifiedLabel>
  );
});

export const makeUnifiedInput = (fieldConfig) => forwardRef((props, ref) => (
  <UnifiedInput {...props} fieldConfig={fieldConfig} ref={ref} />
));

export default UnifiedInput;
