import { Status } from './enums';

// initial states can be fetched multiple times. Hence always return new object,
// so that mutations to arrays etc, will not affect future fetches.
export default () => ({
  asyncTasks: {
    all: [],
    pending: [],
  },
  background: {
    activities: [],
    isManaging: false,
  },
  // 'global' contains states used across most pages
  global: {
    globalEnumsLoaded: false,
    tenantSettings: {
      carriyoSsoEnabled: false,
      enterpriseSsoEnabled: false,
      skipValidations: [],
      limits: {},
      translationLanguages: ['en'],
      translationLanguageOptions: [{
        label: 'English',
        value: 'en',
      }],
    },
    merchants: [],
    merchantIds: [],
    selectedMerchants: [],
    selectedMerchantIds: [],
    selectedMerchantIdsWithReturnRequestEnabled: [],
    allMerchantsSelected: false,

    // account level access flags
    canAccessAccountNotificationSettings: false,
    canShowAccountNotificationSettings: false,

    // enums
    carrierMetadata: [],

    orderTypes: [],
    orderTypesForAccount: [],
    allOrderTypes: [],

    deliveryTypes: [],
    deliveryTypesForAccount: [],
    allDeliveryTypes: [],

    reasonCodes: [],

    locations: [],
    locationsForAccount: [],
    allLocations: [],
    userAccessibleLocations: [],
    userAccessibleCountries: [],

    customAttributes: [],
    customAttributesForAccount: [],
    allCustomAttributes: [],

    // flags for first merchant selected
    emailNotificationEnabled: false,
    smsNotificationEnabled: false,
    whatsappNotificationEnabled: false,
    feedbackEnabled: false,
    pinpointLocationEnabled: false,
    trackingEnabled: false,
    returnsEnabled: false,

    addressModelByCountry: null,
  },
  // other shared states or shared component states
  cities: {},
  states: {},
  auth: {
    authorizing: true,
    user: {},
    role: '',
    merchants: [],
    accessByTenantId: {},
    alertCategoryByTenantId: {},
  },
  dialogToDisplay: '',
  loading: 0,
  toast: {
    showToast: false,
    icon: '',
    message: '',
  },
  cachedEntities: {
    locations: [],
    carriers: [],
  },
  // read-only-cache
  apiClients: [],
  connectors: [],
  users: [],
  // page specific states (mostly)
  carrier: {},
  carrierCapacity: {},
  clicknShipService: {
    allServices: [],
    filters: {},
    actionCallTriggered: false,
  },
  shipmentCosting: {},
  notificationSettings: {
    smsSettings: {},
    emailSettings: {},
    whatsappSettings: {},
  },
  shipmentNotificationSettings: {
    smsSettings: {},
    smsTriggers: {},
    smsTemplateOverrides: {},

    emailSettings: {},
    emailTriggers: {},
    emailTemplateOverrides: {},

    whatsappSettings: {},
    whatsappTriggers: {},
  },
  reverseShipmentNotificationSettings: {
    smsSettings: {},
    smsTriggers: {},
    smsTemplateOverrides: {},

    emailSettings: {},
    emailTriggers: {},
    emailTemplateOverrides: {},

    whatsappSettings: {},
    whatsappTriggers: {},
  },
  returnRequestNotificationSettings: {
    smsSettings: {},
    smsTriggers: {},
    smsTemplateOverrides: {},

    emailSettings: {},
    emailTriggers: {},
    emailTemplateOverrides: {},

    whatsappSettings: {},
    whatsappTriggers: {},
  },
  location: {},
  locationsList: {
    locations: [],
  },
  network: {},
  manifest: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
  },
  merchantList: {
    merchants: [],
    rowsPerPageOptions: [10, 25, 50],
  },
  alerts: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
    alertList: [],
    newAlertsCount: 0,
  },
  reports: {
    serviceLevels: {},
    carrierPerformance: {},
    shipmentHistory: {},
    transitTime: {},
    ageingShipments: {},
    errorHistory: {},
    regionalAnalysis: {},
    notifications: { meta: {} },
    feedbacks: {
      totalRatings: 0,
      averageRating: 0,
      ratingsBreakdown: [
        {
          rating: 5,
          count: 0,
        }, {
          rating: 4,
          count: 0,
        }, {
          rating: 3,
          count: 0,
        }, {
          rating: 2,
          count: 0,
        }, {
          rating: 1,
          count: 0,
        },
      ],
      positiveReasonsBreakdown: [],
      negativeReasonsBreakdown: [],
    },
  },
  returnRequestDetail: { collection: {} },
  returnRequestList: {
    rowsPerPageOptions: [10, 25, 50],
    totalRows: 0,
    returnRequests: [],
  },
  returnRequestSettings: {},
  reverseShipmentRuleList: {
    searchString: '',
    sortBy: 'priority',
    sortDirection: 'asc',
    rules: [],
    ruleIdToDelete: null,

    filters: {
      active: true,
    },
  },
  route: {
    permissions: {},
  },
  rule: {
    name: '',
    description: '',
    priority: '',
    orderLimit: {},
    orderValue: {
      from: 0,
      to: 100000,
    },
    itemValue: {
      from: 0,
      to: 100000,
    },
    parcelCount: {
      from: 0,
    },
    grossWeight: {
      from: 0,
    },
    chargeableWeight: {
      from: 0,
    },
    volumetricWeight: {
      from: 0,
    },
    orderTypes: [],
    deliveryTypes: [],
    merchant: [],
    paymentTypes: [],
    conditions: [],
    active: true,
  },
  ruleList: {
    searchString: '',
    sortBy: 'priority',
    sortDirection: 'asc',
    rules: [],
    ruleIdToDelete: null,

    filters: {
      active: true,
    },
  },
  ruleSetList: {
    searchString: '',
    sortBy: 'priority',
    sortDirection: 'asc',
    ruleSets: [],
    ruleSetIdToDelete: null,
    filters: {
      active: true,
    },
  },
  serviceLevelSetList: {
    searchString: '',
    sortBy: 'priority',
    sortDirection: 'asc',
    serviceLevelSets: [],
    serviceLevelSetIdToDelete: null,
    filters: {
      active: true,
    },
  },
  serviceLevel: {},
  serviceLevelsSettings: {
    configs: [],
    activeTab: 'FULFILLMENT',
  },
  shipmentList: {
    rowsPerPageOptions: [10, 25, 50, 100],
    totalRows: 0,
    rawShipments: [],
    shipments: [],
    multiSelectedShipmentIds: [],
    uploadList: [],
    uploadListResults: [],
  },
  tenantSettings: {
    tenantId: null,
    tenantName: null,
    defaultCountry: null,
    defaultCurrency: null,
    merchants: {},
    sortBy: 'id',
    sortDirection: 'asc',
  },
  themeAndBrandConfigs: {
    Theme: {},
    BrandProfile: {},
  },
  userList: {
    rowsPerPageOptions: [10, 25, 50],
    pageSize: 10,
    page: 0,
    total: 0,
    totalNonCarriyoActiveUsers: 0,
    sortBy: 'creation_date',
    sortDirection: 'desc',
    users: [],
  },
  customDomains: {
    list: [],
  },
  webhookSettings: {
    settings: [],
    fetched: false,
    selectedWebhook: {
      status: Status.active,
      configName: '',
      url: '',
      headers: { 'Content-Type': 'application/json' },
      entityType: 'SHIPMENT',
      notifyStatus: [],
      notifyLabelUpdate: false,
      notifyCarriyoLabelUpdate: false,
      conditions: [],
    },
  },
  system: {
    deployment: {
      extant: undefined,
      latest: undefined,
    },
    warning: undefined,
  },
  pageViews: {
    forwardShipment: [],
    reverseShipment: [],
  },
});
